import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Select,
  message,
  Typography,
  Modal,
  DatePicker,
  Result,
  Checkbox
} from "antd";
import { InvestorService } from "../Investor.service";
import eversign from "./Eversign";
import { useHistory } from "react-router";
import { SharedService } from "../../Shared/Shared.service";
import ppm from "../../../assets/Documents/CAR - PPM.pdf"
import a2Form from "../../../assets/Documents/Form A2.pdf"

const sharedService = new SharedService();
const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;
const investorService = new InvestorService();
const layout = {
  layout: 'vertical',
  labelCol: {
    span: 16,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 6,
  },
};
const validation = [
  {
    required: true,
    message: "Please enter a valid amount",
  },
  {
    validator: (text, value) => {
      let isNum = /^\d+$/.test(value);
      if (!isNum)
        return new Promise(async (resolve, reject) =>
          reject("Please enter a valid amount")
        );
      else return new Promise(async (resolve, reject) => resolve());
    },
  },
];

export default ({ token, userInfo, onBack }) => {
  const [localCurrency, setLocalCurrency] = useState("USD");
  const [completePaymentURL, setCompletePaymentURL] = useState(null);
  const [bankAccountDetails, setBankAccountDetails] = useState(null);
  const [serviceProvider, setServiceProvider] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  //const [modalView, setModalView] = useState(true);
  const [signURL, setSignURL] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [othersForm] = Form.useForm();
  const [paymentDone, setPaymentDone] = useState(false);
  const [ppmAccepted, setPPMAccepted] = useState(false);
  const history = useHistory();
  
  
  // useEffect(() => {
  //   (async () => {
  //     const response = await investorService.getInvestSignURL(userInfo);
  //     await setSignURL(response.data);
  //   })();
  // }, []);

  const fetchSignUrl = async formData => {
    setPaymentData(formData)
    try {
      const noOfTokens = Number((formData.amountTransfered / token.pricePerToken) * 100)
      const data = {...userInfo, noOfTokens}
      const response = await investorService.getInvestSignURL(data)
      await setSignURL(response.data);
    } catch (e) {
      console.log(e)
      message.error("Something Went Wrong")
    }
  }
  
  useEffect(() => {
    (async () => {
      const response = await investorService.getBankDetails({
        companyId: token.issuerInfo[0].companyId,
      });
      if (response.success && response.data) {
        console.log("Others Payment", response.data);
        setBankAccountDetails(response.data);
      } else {
        message.error("Error fetching bank account details");
      }
    })();
  }, []);

  const makePaymentUtil = async (data) => {
    setSubmitting(true);
    const dataToSend = {
      agent:
        data.serviceProvider === 3
          ? data.nameOfServiceProvider
          : data.serviceProvider,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      email: userInfo?.email,
      amount: data.amountTransfered + localCurrency,
      amountToInvest: Number(data.amountTransfered),
      no_of_tokens: Number((data.amountTransfered / token.pricePerToken) * 100),
      refrenceNumber: data.refrenceNumber,
      token: token,
      modeOfFundTransfer: "wire",
      investorId: userInfo._id,
      phoneNumber: userInfo?.contactNumber,
      dateOfAmountReceived: data.dateOfAmountReceived,
      company: token.issuerInfo[0].companyName.toLowerCase(),
      accept: false,
      reject: false,
    };
    
    try {
      const response = await investorService.payUsingOthers(dataToSend);
      if (response.success && response.data) {
        console.log(response.data);
        othersForm.resetFields();
        setPaymentDone(true);
        const eversignDiv = document.getElementById("embedInvestSignDivisionOther");
    	eversignDiv.innerHTML = '';
    	setSubmitting(false);
        message.success("Payment details succesfully saved");
      } else {
        message.error("Something went wrong please try again");
        const eversignDiv = document.getElementById("embedInvestSignDivisionOther");
    	eversignDiv.innerHTML = '';
    	setSubmitting(false);
      }
    } catch (e) {
      console.log(e);
      message.error("Something went wrong please try again");
      const eversignDiv = document.getElementById("embedInvestSignDivisionOther");
      eversignDiv.innerHTML = '';
      setSubmitting(false);
    }
  };
  const CurrencySelector = () => {
    const [currencyList, setCurrencyList] = useState(null);
    useEffect(() => {
      (async () => {
        const response = await sharedService.getCurrencyList();
        if (response.success && response.data) {
          setCurrencyList(response.data);
        } else {
          setCurrencyList([]);
          message.error("Failed to load currency list");
        }
      })();
    }, []);
    return (
      <>
        <Select
          defaultValue={localCurrency}
          onChange={(e) => {
            setLocalCurrency(e);
          }}
          className="select-after"
          loading={!currencyList}
          showSearch
        >
          {currencyList?.map((data, index) => (
            <Option value={data?.currency?.trim()} key={index}>
              {data?.currency?.trim()}
            </Option>
          ))}
        </Select>
      </>
    );
  };

  return (
    <>
      {!(paymentDone || paymentData !== null) && 
      <Row>
        <Col span={16}>
        {/*<div hidden={paymentDone || paymentData!==null}>*/}
        <Form form={othersForm} {...layout} onFinish={fetchSignUrl}>
          <Item
            name="serviceProvider"
            label="Select payment service provider"
            rules={[
              {
                required: true,
                message: "Please enter a valid amount",
              },
            ]}
          >
            <Select
              value={serviceProvider}
              onChange={(e) => {
                setServiceProvider(e);
              }}
            >
              <Option value="Western union">Western union</Option>
              <Option value="ICICI direct">ICICI direct</Option>
              <Option value="HDFC Bank">HDFC Bank</Option>
              <Option value="IDFC Bank">IDFC Bank</Option>
              <Option value="Kotak Mahindra Bank">Kotak Mahindra Bank</Option>
              <Option value={3}>Others</Option>
            </Select>
          </Item>

          {serviceProvider === 3 && (
            <Item
              name="nameOfServiceProvider"
              label="Name of service provider"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid amount",
                },
              ]}
            ><br />
              <Input></Input>
            </Item>
          )}

          <Item
            name="amountTransfered"
            label="Amount transfered"
            rules={validation}
          >
            <Input
              addonAfter={<CurrencySelector />}
              disabled={submitting}
            ></Input>
          </Item>

          <Item
            name="refrenceNumber"
            label="Reference number"
            rules={[
              {
                required: true,
                message: "Please enter a valid amount",
              },
            ]}
          >
            <Input></Input>
          </Item>

          <Item
            name="dateOfAmountReceived"
            label="Date of Amount transfer"
            rules={[
              {
                required: true,
                message: "Please enter a valid amount",
              },
            ]}
          >
            <DatePicker />
          </Item>
          <Item>
          	<Checkbox
          		checked={ppmAccepted}
          		onChange={e => setPPMAccepted(e.target.checked)}
          	>
          	<a target="#" href={ppm}>I understand and agree to the PPM of the offering</a>
          	</Checkbox>
          </Item>
          <Item {...tailLayout}>
            <Button disabled={!ppmAccepted} htmlType="submit" type="primary" loading={submitting}>
              Submit
            </Button>
          </Item>
        </Form>
      </Col>
      <Col span={8}>
        {/*<Modal
          title="Issuer Bank Details"
          closable={false}
          visible={modalView}
          footer={[
            <Button
              type="primary"
              htmlType="button"
              onClick={() => {
                setModalView(false);
              }}
            >
              OK
            </Button>,
          ]}
        >*/}
        <p
          style={{
            fontWeight: "bold",
            //fontSize: "24px",
            //color: "#4285F4",
          }}
        >
          Issuer Bank details for Wire Transfer:
        </p>
          {
            <>
              <b>Recipient Name </b><br />
              {bankAccountDetails?.recipientName}
              <br />
             {/* <b>Recipient Account Type</b><br />
              {bankAccountDetails?.accountType}
             <br />*/}
              <b>Recipient Bank Routing number</b><br />
              {bankAccountDetails?.ACHRoutingNumber}
              <br />
              <b>Recipient Bank Name</b><br />
              {bankAccountDetails?.bankName}
              <br />
              <b>Recipient Bank Account Number</b> <br />{bankAccountDetails?.bankAccountNumber}
              <br />
              <b>Recipient Bank Address</b> <br />{bankAccountDetails?.bankAddress}
              <br />
              <b>Recipient Bank Swift Code</b> <br />{bankAccountDetails?.swiftCode}
              <br />
              <b>Recipient Bank Phone No</b> <br />{bankAccountDetails?.phoneNo}
              <br />
             {/*<b>Name Of Beneficiary</b><br />
              {bankAccountDetails?.nameOfBeneficiary} 
              <br />*/}
              <b><a target="_blank" href={a2Form}>For India Investors - Please download Form A2 for the bank wire transfer</a></b>
            </>
          }
          <br />
      </Col>
    </Row>}
     <div hidden={!paymentDone}>
        <Result
          status="success"
          title="Payment Completed"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                onBack();
              }}
            >
              Home
            </Button>,
          ]}
        ></Result>
      </div> 
      <div hidden={paymentDone} id="embedInvestSignDivisionOther">
           <>
           {signURL!==null && eversign.open({
              url: signURL,
              containerID: "embedInvestSignDivisionOther",
              width: 750,
              height: 800,
              events: {
                loaded: () => console.log("load hogya"),
                signed: () => makePaymentUtil(paymentData),
                error: () => console.log("EVERSIGN ERROR"),
                declined: () => {
                    message.error("Payment Declined");
                    setPaymentData(null);
                    window.location.reload();
                },
              },
            })}
           </>
      </div>
    </>
  );
};
