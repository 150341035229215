export const environment = {
  deployment: "prod",
  production: true,
  apiKey: "5f6b7dff0f95ad9f02444cd0",
  captchaKey: "6LfV5ccZAAAAACClqoeS8Vv8B_HyXJfd57AaFvKI",
  APIURL: "https://car-backend-prod.herokuapp.com",
  headerTitle: "Crypto Asset Rating Inc",
  securityTokenRegistryAddress: '0x80832F14c073637b6dDEb458ad243174E6806BAf',
  tapRegistryAddress: "0x08beA18b235FE89F7990B40179Dcc6f1cC561191",
  allowedNetworks: ['137'],
  NORTH_CAPITAL_API_KEY: '6hLi5AHquGXBmqRh6n6s7JQg07VQKXYngm7',
  NORTH_CAPITAL_CLIENT_ID: '4MdYUpAArsDBZ7e',
  monthUnitInSeconds: 30 * 24 * 60 * 60,
};
