import React from "react";
// import { lazy } from '@loadable/component';
import { Switch, Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Account from "./components/Account";
import Register from "./components/Register";
import CompanyDetails from "./components/Investor/Home/CompanyDetails";
import PlatformIssuerAdmin from "./components/PlatformIssuerAdmin";
import IssuerSuperAdmin from "./components/IssuerSuperAdmin";
import TeamMember from "./components/TeamMember";
import TokenDashboard from "./components/TokenDashboard";
import IssuerTokenAdmin from "./components/IssuerTokenAdmin";
import PlatformSuperAdmin from "./components/PlatformSuperAdmin";
import Investor from "./components/Investor";
import IssuerCounsel from "./components/IssuerCounsel";
import PlatformComplianceAdmin from "./components/PlatformComplianceAdmin";
import { deviceDetect } from "react-device-detect";
import IssuerComplianceAdmin from "./components/IssuerComplianceAdmin";
import { User } from "./components/Shared/interfaces";
import ApproversAndAccounts from "./components/IssuerSuperAdmin/ApproversAndAccounts";
import TokenConfigurations from "./components/TokenConfigurations";
import RecoveryRequests from "./components/RecoveryRequests";
import AuthorizationRequest from "./components/AuthorizationRequest";
import Demo from "./containers/templateConfig/mainLayout";
import NavHOC from "./components/NavHOC";
import { library } from "@fortawesome/fontawesome-svg-core";
import Footer from "./layout/footer";
import PrivacyPolicy from "./components/privacy-policy";
import Disclaimer from "./components/Disclaimer";
import { AccountService } from "./components/Account/Account.service";
import fontAwesomeIcon from "./utility/icons/fontawesome";
import TermsOfUse from "./components/TermsOfUse";
import OuterHeader from "./layout/header/OuterHeader";
import WhitelistedWalletRequests from "./components/WhitelistedWalletRequests";
import SubscriptionAgreement from "./components/SubscriptionAgreement";
import TrustedForwarder from "./components/TrustedForwarder";
library.add(...fontAwesomeIcon);
const jQuery = require("jquery");

declare global {
  interface Window {
    $: any;
    jQuery: any;
  }
}
window.$ = jQuery;
window.jQuery = jQuery;

// const Intro = lazy(() => import('./components/Intro'));
// const Account = lazy(() => import('./components/Account'));
const accountService = new AccountService();
const currentDeviceId = deviceDetect();
var onceErrorMessageShown = false;
const CustomRoute = ({
  children,
  userInfo,
  ...rest
}: {
  children: any;
  userInfo: User;
  path: string;
  exact?: boolean;
}) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (userInfo === undefined) return;

        if (userInfo) {
          if (userInfo.role === "issuer super admin") {
            if (
              userInfo.status.firstTimeLoggedIn &&
              location.pathname !== "/account/TOTP"
            ) {
              //return (<Redirect to={{ pathname: '/account/TOTP', state: { from: location } }} />);
            }
            if (
              (userInfo["differentIP"] ||
                userInfo["differentDevice"] ||
                userInfo["deviceExpired"]) &&
              location.pathname !== "/account/2FA" &&
              location.pathname !== "/account/TOTP"
            ) {
              //return (<Redirect to={{ pathname: '/account/2FA', state: { from: location } }} />);
            }

            if (
              location.pathname !== "/account/TOTP" &&
              location.pathname !== "/account/2FA"
            ) {
              if (userInfo.status.registrationRequest !== "verified") {
                if (
                  location.pathname !== "/issuer-super-admin/registration-verif"
                )
                  return (
                    <Redirect
                      to={{
                        pathname: "/issuer-super-admin/registration-verif",
                        state: { from: location },
                      }}
                    />
                  );
              } else {
                if (userInfo.status.basicInfo !== "verified") {
                  if (
                    location.pathname !== "/issuer-super-admin/basic-info-verif"
                  ) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/issuer-super-admin/basic-info-verif",
                          state: { from: location },
                        }}
                      />
                    );
                  }
                } else {
                  let response = accountService.isAllQuestionAnswered({
                    company: userInfo.company?.name?.toLowerCase(),
                  });
                  response.then(function (data: any) {
                    if (data.data === true) {
                      if (
                        location.pathname !==
                          "/issuer-super-admin/information-questions" &&
                        location.pathname !==
                          "/issuer-super-admin/view-and-edit-answers"
                      ) {
                        history.push(
                          "/issuer-super-admin/information-questions"
                        );
                      }
                    } else {
                      let response = accountService.isAllDDAnswered({
                        companyId: userInfo.company?.id,
                      });
                      response.then(function (data: any) {
                        if (data.data === true) {
                          if (
                            location.pathname !==
                            "/issuer-super-admin/answer-due-diligence"
                          ) {
                            history.push(
                              "/issuer-super-admin/answer-due-diligence"
                            );
                          }
                        }
                      });
                    }
                  });
                }
              }
            }
          }
          if (userInfo.role === "investor") {
            if (userInfo.status.emailVerified === false) {
              if (location.pathname !== "/investor/email-not-verified") {
                history.push("/investor/email-not-verified");
              }
            } else {
              // const minElapsed = (Date.now() - userInfo.dateOfDeviceRegistration) / 60000;
              // //30 daya=43200 min
              // if (JSON.stringify(userInfo.deviceId) !== JSON.stringify(currentDeviceId) || minElapsed > 43200) {
              //   if (location.pathname !== '/account/device-registration') {
              //   history.push('/account/device-registration');
              //   }
              // }
            }
          }
          return children;
        } else {
          if (
            location.pathname.startsWith("/account") ||
            location.pathname === "/" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/terms-of-use" ||
            location.pathname === "/disclaimer" ||
            location.pathname === "/subscriptionagreement"
          )
            return children;
          else
            return (
              <Redirect to={{ pathname: "/", state: { from: location } }} />
            );
        }
      }}
    />
  );
};

export default ({ userInfo }) => {
  const history = useHistory();
  return (
    <div className="">
      <Switch>
        <CustomRoute exact path="/" userInfo={userInfo}>
          {/* <CompanyDetails company="crypto asset rating inc." companyId="6010de09ff80a300316fd60a" investButtonCallback={()=>history.push('/account/login')} /> */}
          <Demo />
        </CustomRoute>
        <CustomRoute path="/account" userInfo={userInfo}>
          <NavHOC component={<Account />} />
        </CustomRoute>
        <CustomRoute path="/platform-issuer-admin" userInfo={userInfo}>
          <NavHOC component={<PlatformIssuerAdmin />} />
        </CustomRoute>
        <CustomRoute path="/issuer-super-admin" userInfo={userInfo}>
          <NavHOC component={<IssuerSuperAdmin />} />
        </CustomRoute>
        {/* <CustomRoute path="/token-configuration/:configType" userInfo={userInfo}>
          <TokenConfiguration/>
        </CustomRoute> */}
        <CustomRoute path="/token-configurations" userInfo={userInfo}>
          <NavHOC component={<TokenConfigurations />} />
        </CustomRoute>
        <CustomRoute path="/team-member" userInfo={userInfo}>
          <NavHOC component={<TeamMember />} />
        </CustomRoute>
        <CustomRoute path="/token-dashboard" userInfo={userInfo}>
          <NavHOC component={<TokenDashboard />} />
        </CustomRoute>
        <CustomRoute path="/issuer-token-admin" userInfo={userInfo}>
          <NavHOC component={<IssuerTokenAdmin />} />
        </CustomRoute>
        <CustomRoute path="/platform-super-admin" userInfo={userInfo}>
          <NavHOC component={<PlatformSuperAdmin />} />
        </CustomRoute>
        <CustomRoute path="/platform-compliance-admin" userInfo={userInfo}>
          <NavHOC component={<PlatformComplianceAdmin />} />
        </CustomRoute>
        <CustomRoute path="/investor" userInfo={userInfo}>
          <NavHOC component={<Investor />} />
        </CustomRoute>
        <CustomRoute path="/issuer-counsel" userInfo={userInfo}>
          <NavHOC component={<IssuerCounsel />} />
        </CustomRoute>
        <CustomRoute path="/issuer-compliance-admin" userInfo={userInfo}>
          <NavHOC component={<IssuerComplianceAdmin />} />
        </CustomRoute>

        <CustomRoute path="/approvers-and-accounts" userInfo={userInfo}>
          <NavHOC component={<ApproversAndAccounts />} />
        </CustomRoute>

        <CustomRoute path="/authorization-request-review" userInfo={userInfo}>
          <NavHOC component={<AuthorizationRequest />} />
        </CustomRoute>

        <CustomRoute path="/recovery-requests" userInfo={userInfo}>
          <NavHOC component={<RecoveryRequests />} />
        </CustomRoute>

        <CustomRoute path="/whitelisted-wallet-requests" userInfo={userInfo}>
          <NavHOC component={<WhitelistedWalletRequests />} />
        </CustomRoute>

        <CustomRoute path="/trusted-forwarder" userInfo={userInfo}>
          <NavHOC component={<TrustedForwarder />} />
        </CustomRoute>


        <CustomRoute path="/privacy-policy" userInfo={userInfo}>
          <OuterHeader />
          <PrivacyPolicy />
          <Footer></Footer>
        </CustomRoute>

        <CustomRoute path="/terms-of-use" userInfo={userInfo}>
          <OuterHeader />
          <TermsOfUse />
          <Footer></Footer>
        </CustomRoute>

        <CustomRoute path="/disclaimer" userInfo={userInfo}>
          <OuterHeader />
          <Disclaimer />
          <Footer></Footer>
        </CustomRoute>


        <CustomRoute path="/subscriptionagreement" userInfo={userInfo}>
          <OuterHeader />
          <SubscriptionAgreement />
          <Footer></Footer>
        </CustomRoute>
      </Switch>
    </div>
  );
};
