import React, {
  useState,
  useEffect,
  Fragment
} from "react";
import {
  Card,
  Input,
  Button,
  Row,
  Col,
  Form,
  Typography,
  Result,
  Checkbox,
  List,
  message, notification,
} from "antd";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  filter,
} from "rxjs/operators";
import { from, Observable } from "rxjs";
import { useEventCallback } from "rxjs-hooks";
import {
  TokenConfigurationProcess,
  SymbolDetailsAndSTData,
  Rule144Config,
  User,
  RegLaunched, TokenTypeAndDetails,
} from "../../../Shared/interfaces";
import { SharedService } from "../../../Shared/Shared.service";
import { TokenConfigurationService } from "../../TokenConfiguration.service";
import { SecurityTokenService } from "../../../Shared/SecurityToken/SecurityToken.service";
import { SecurityTokenRegistryService } from "../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import MainFacet from "../../../Shared/SecurityToken/Facets/MainFacet/index";
import Rule144Facet from "../../../Shared/SecurityToken/Facets/Rule144Facet/index";
import TransactionModal from "../../../Shared/TransactionModal";
import BigNumber from "bignumber.js";
import moment from "moment";
import {TrustedForwarderService} from "../../../Shared/TrustedForwarder.service";
import TxFeeDelegationModal from "../../../Shared/TxFeeDelegationModal";

const { Text, Title } = Typography;
const { TextArea } = Input;

const securityTokenRegistryService = new SecurityTokenRegistryService();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenService = new SecurityTokenService();
const rule144Facet = new Rule144Facet();
const mainFacet = new MainFacet();
const trustedForwarderService = new TrustedForwarderService();

const etherscanURL = sharedService.etherscanURL;

const ReviewComponent = ({
  tokenConfigurationProcess,
  userInfo,
  _submitTokenConfigForReview,
  isserCounselCheckBoxesForm,
  _reviewFinalTokenConfig,
  submitting,
  isserCounselCheckBoxes,
  setComments,
  comments,
  rejecting,
  verifiying,
}) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
  }
  return (
    <>
      {
      !tokenConfigurationProcess?.legendCreationFile && (
        <Result title="Legend not created by Issuer Counsel" />
      )
      }
      {
        tokenConfigurationProcess?.legendCreationFile && !tokenConfigurationProcess.isLegendAdded && (
          <Result title="Legend not added to your Token" />
        )
      }
      {
        tokenConfigurationProcess?.isLegendAdded && userInfo.role === "issuer token admin" && ["token creation"].includes(tokenConfigurationProcess.status) && (
          <>
            {tokenConfigurationProcess.allCheckboxesSelected && (
              <Result
                title="Legend added"
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    loading={submitting}
                    onClick={_submitTokenConfigForReview}
                  >
                    Submit Configuration for Review
                  </Button>,
                ]}
              />
            )}
          </>
        )
      }
      {
        tokenConfigurationProcess?.status === "pending issuer super admin" && userInfo.role !== "issuer super admin" && (
          <Result title="Issuer Super Admin is reviewing your Token Configuration" />
        )
      }
      {
        tokenConfigurationProcess?.status === "rejected issuer super admin" && (
          <Result
            status="error"
            title="Issuer Super Admin rejected your Token Configuration"
            subTitle={
              userInfo.role === "issuer token admin" &&
              tokenConfigurationProcess.comments &&
              `Reason: ${tokenConfigurationProcess.comments}`
            }
            extra={[
              userInfo.role === "issuer token admin" ? (
                <Button
                  type="primary"
                  key="console"
                  loading={submitting}
                  onClick={_submitTokenConfigForReview}
                >
                  Submit Configuration for Review
                </Button>
              ) : null,
            ]}
          />
        )
      }
      {
        tokenConfigurationProcess?.status === "pending issuer company officer" && userInfo.role !== "issuer company officer" && (
          <Result title="Issuer Company Officer is reviewing your Token Configuration" />
        )
      }
      {
        tokenConfigurationProcess?.status === "rejected issuer company officer" && (
          <Result
            status="error"
            title="Issuer Company Officer rejected your Token Configuration"
            subTitle={
              userInfo.role === "issuer super admin" &&
              tokenConfigurationProcess.comments &&
              `Reason: ${tokenConfigurationProcess.comments}`
            }
          />
        )
      }
      {
        tokenConfigurationProcess?.status === "pending issuer counsel" && userInfo.role !== "issuer counsel" && (
          <Result title="Issuer Counsel is reviewing your Token Configuration" />
        )
      }
      {
        tokenConfigurationProcess?.status === "rejected issuer counsel" && (
          <Result
            status="error"
            title="Issuer Counsel rejected your Token Configuration"
            subTitle={
              userInfo.role === "issuer company officer" &&
              tokenConfigurationProcess.comments &&
              `Reason: ${tokenConfigurationProcess.comments}`
            }
          />
        )
      }
      {
        tokenConfigurationProcess?.status === "verified" && (
          <Result
            status="success"
            title="Token Configuration has been approved by Issuer Super Admin, Issuer Company Officer and Issuer Counsel"
          />
        )
      }
      {
        userInfo.role === "issuer counsel" && tokenConfigurationProcess?.regulation === "regulation-s" && ["pending issuer counsel"].includes(tokenConfigurationProcess?.status as string) && (
          <>
            <Title level={3} style={{ textAlign: "center", color: "blue" }}>
              Requirement for Issuer Counsel under Reg S
            </Title>
            <Form
              form={isserCounselCheckBoxesForm}
              labelAlign="right"
              wrapperCol={{ xs: { span: 24 }, sm: { offset: 2 } }}
            >
              {
                isserCounselCheckBoxes?.map((checkbox, index) => (
                  <Fragment key={index}>
                    <Form.Item
                      style={{ marginBottom: "0px" }}
                      valuePropName="checked"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                      name={checkbox.name}
                    >
                      <Checkbox style={{ color: "blue" }}>
                        <span style={{ color: "blue" }}>{checkbox.label}</span>
                      </Checkbox>
                    </Form.Item>
                  </Fragment>
                ))
              }
            </Form>
          </>
        )
      }
      {
        ((userInfo.role === "issuer super admin" &&
          [
            "pending issuer super admin",
            "rejected issuer company officer",
          ].includes(tokenConfigurationProcess?.status as string)) ||
          (userInfo.role === "issuer company officer" &&
            [
              "pending issuer company officer",
              "rejected issuer counsel",
            ].includes(tokenConfigurationProcess?.status as string)) ||
          (userInfo.role === "issuer counsel" &&
            ["pending issuer counsel"].includes(
              tokenConfigurationProcess?.status as string
            ))) && (
          <>
            <h2>Comments</h2>
            <TextArea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></TextArea>
            <br />
            <br />
            <Checkbox value={checked} onChange={onChange}> I understand and agree to safe harbor </Checkbox>
            <br />
            <br />
            <Button
              key="reject"
              danger
              disabled={verifiying}
              loading={rejecting}
              onClick={() => _reviewFinalTokenConfig("rejected")}
            >
              Reject
            </Button>
            <Button
              key="approve"
              type="primary"
              style={{
                marginLeft: "5px"
              }}
              disabled={rejecting}
              loading={verifiying}
              onClick={() => {
                if (!checked) {
                  message.info("Please agree to safe harbor")
                  return;
                }
                _reviewFinalTokenConfig("verified")
              }}>
              Approve
            </Button>
          </>
        )
      }
    </>
  );
};

export default ({
  reserveTokenSymbol,
  selectedWallet,
  networkId,
  symbolDetailsAndSTData,
  updateSymbolDetailsAndSTData,
  tokenConfigurationProcess,
  userInfo,
  submitTokenConfigForReview,
  reviewFinalTokenConfig,
  submitCheckboxesStatus,
  isRelaunch,
}: {
  reserveTokenSymbol;
  selectedWallet: string | undefined;
  networkId: string | undefined;
  symbolDetailsAndSTData: SymbolDetailsAndSTData | undefined;
  tokenConfigurationProcess: TokenConfigurationProcess | undefined;
  updateSymbolDetailsAndSTData;
  userInfo: User;
  submitTokenConfigForReview;
  reviewFinalTokenConfig;
  submitCheckboxesStatus;
  isRelaunch: boolean;
}) => {

  const [form] = Form.useForm();
  const [checkBoxesForm] = Form.useForm();
  const [isserCounselCheckBoxesForm] = Form.useForm();
  const [transactions, setTransactions] = useState<{ submitting?: boolean; receipt?: any; details: string }[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tokenSymbolRulesValid, setTokenSymbolRulesValid] = useState(false);
  const [chekingAvailability, setChekingAvailability] = useState<boolean>(false);
  const [rule144ConfigInitialized, setRule144ConfigInitialized] = useState<boolean>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [updatingSymbolDetailsAndSTData, setUpdatingSymbolDetailsAndSTData] = useState<boolean>();
  // const [symbolRegEvent, setSymbolRegEvent] = useState<any>();
  const [expiryDateCountDown, setExpiryDateCountDown] = useState<any>();
  const [loadingRule144ConfigInitialized, setLoadingRule144ConfigInitialized] = useState<boolean>();
  const [comments, setComments] = useState("");
  const [verifiying, setVerifiying] = useState<boolean>();
  const [rejecting, setRejecting] = useState<boolean>();
  const [issuerQuestionAnswer, setIssuerQuestionAnswer] = useState<any[]>();
  const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>([]);

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const [txParams, setTxParams] = useState<any>();

  const isserCounselCheckBoxes = [
    { label: "Completion of safe harbor", name: "completion of safe harbor" },
    { label: "Due diligence", name: "due diligence" },
    { label: "Disclosure drafting", name: "disclosure drafting" },
    { label: "Preparation of transaction documentation", name: "preparation of transaction documentation", },
  ];
  const tokenConfigCheckboxes = [
    { label: "TOKEN TYPE & DETAILS", name: "tokenTypeAndDetails" },
    { label: "RULE 144", name: "rule144" },
    { label: "LEGEND", name: "legend" },
    { label: "PHASE", name: "phase" },
    { label: "TOKEN SYMBOL", name: "tokenSymbol" },
  ];

  const precision = securityTokenService.precision;

  const isRegRelaunched = regulationsLaunched.some(
    (regLaunched) =>
      tokenConfigurationProcess?.regulation === sharedService.regOptions.find(
        (regOp) =>
          regOp.shortValue === regLaunched.regulation &&
          regLaunched.creationTS.toString() ===
          tokenConfigurationProcess?.creationTS.toString()
      )?.value
  );

  const [setTokenSymbol, isTokenSymbolAvailable] = useEventCallback(
    (tokenSymbol$: Observable<string>) =>
      tokenSymbol$.pipe(
        tap(() => setTokenSymbolRulesValid(false)),
        filter((term) => !!term && !!term.trim() && term.length <= 10), //VALIDATIONS
        tap(() => setTokenSymbolRulesValid(true)),
        tap(() => setChekingAvailability(true)),
        debounceTime(600),
        distinctUntilChanged(),
        switchMap((term) =>
          from(
            securityTokenRegistryService.tokenSymbolAvailable(
              term.toUpperCase()
            )
          )
        ),
        tap(() => setChekingAvailability(false))
      )
  );

  useEffect(() => {
    (async () => {
      if (!symbolDetailsAndSTData) return;
      // const symbolRegistrationEvents = await securityTokenRegistryService.getSymbolRegistrationEvent(
      //   symbolDetailsAndSTData.symbolDetails.owner as string,
      //   symbolDetailsAndSTData?.symbolDetails.registrationDate as string
      // );
      // setSymbolRegEvent(symbolRegistrationEvents[0]);
      if (!symbolDetailsAndSTData.symbolDetails.isDeployed) return;
      setLoadingRule144ConfigInitialized(true);
      const [_rule144ConfigInitialized, _regulationsLaunched] = await Promise.all([
        rule144Facet.rule144ConfigInitialized(
          symbolDetailsAndSTData.securityTokenData.contractAddress
        ),
        mainFacet.getRegulationsLaunched(
          symbolDetailsAndSTData.securityTokenData.contractAddress
        ),
      ]);
      console.log("_rule144ConfigInitialized:", _rule144ConfigInitialized);
      setRule144ConfigInitialized(_rule144ConfigInitialized);
      setRegulationsLaunched(_regulationsLaunched);
      setLoadingRule144ConfigInitialized(false);
    })();
  }, []);

  useEffect(() => {
    const _isReservedAndNotDeployed = symbolDetailsAndSTData && !symbolDetailsAndSTData.symbolDetails.isDeployed && Date.now() <= +symbolDetailsAndSTData.symbolDetails.expiryDate * 1000;
    if (!symbolDetailsAndSTData || !_isReservedAndNotDeployed) return;
    const timer = sharedService.countdown(
      +symbolDetailsAndSTData.symbolDetails.expiryDate * 1000 - Date.now(),
      (displayable, finished: boolean) => {
        setExpiryDateCountDown(displayable);
      }
    );
    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const _issuerQuestionAnswer: any[] = (
        await tokenConfigurationService.getIssuerQuestionAnswer()
      ).data;
      setIssuerQuestionAnswer(_issuerQuestionAnswer);
      _issuerQuestionAnswer.forEach((checkbox) => {
        checkBoxesForm.setFieldsValue({
          [checkbox.question]: checkbox.isAnswered,
        });
      });
      tokenConfigCheckboxes.forEach((checkbox) => {
        checkBoxesForm.setFieldsValue({ [checkbox.name]: true });
      });
    })();
  }, []);

  const minifyTxHash = (hash: string) => {
    if (!hash) return;
    return sharedService.minifyTxHash(hash);
  };

  const minifyAddress = (hash: string) => {
    return sharedService.minifyAddress(hash);
  };

  const formatDate = (unFormatedDate: string) => {
    return moment(new Date(+unFormatedDate * 1000)).format("LL");
  };

  const openTxFeeDelegationModal = async(formValue) => {
    setTxParams(formValue);
    setIsDelegationModalVisible(true);
  }

  const _reserveTokenSymbol = async (prop: {delegate: boolean}) => {
    if (!isTokenSymbolAvailable) return;

    setIsModalVisible(true);
    setTransactions([{ details: "Reserving Token Symbol", submitting: true }]);


    try {
      const receipt = await reserveTokenSymbol({...txParams, delegate: prop.delegate});
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt;
        return current;
      });
      // if (receipt?.status) setSymbolRegEvent({ transactionHash: receipt.transactionHash });
    } catch (err) {
      console.error(err);
    }
    setTransactions((prev) => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  };

  const createToken = async (prop: {delegate: boolean}) => {
    setIsModalVisible(true);
    setTransactions([
      { details: "Creating your Security Token", submitting: true },
      { details: "Saving Rule 144 Configuration" },
    ]);
    try {

      const [
        rule144Res,
        typeAndDetailsRes,
        issuersWalletsRes,
        trustedForwarderRes
      ] = await Promise.all([
        tokenConfigurationService.getRule144Config(
          tokenConfigurationProcess?.rule144ConfigId as string
        ),
        tokenConfigurationService.getTokenTypeAndDetails(tokenConfigurationProcess?.tokenTypeAndDetailsId as string),
        tokenConfigurationService.getIssuersWallets(),
        trustedForwarderService.getTrustedForwarder()
      ]);

      if (!rule144Res.success || !typeAndDetailsRes.success) {
        setTransactions((prev) => {
          const current = sharedService.clone(prev);
          current[0].submitting = false;
          return current;
        });
        return;
      }

      console.log({trustedForwarderRes});

      if(!trustedForwarderRes.data) {
        setTransactions((prev) => {
          const current = sharedService.clone(prev);
          current[0].submitting = false;
          return current;
        });

        notification.error({
          message: 'No Trusted Forwarder Created',
          description: 'Create one before generating Token'
        });
        return;
      }

      const _trustedForwarder = trustedForwarderRes.data.address;
      //const _monthUnit = 60;
      const _monthUnit = `${networkId}` === '1' ? 2592000 : 60;
      const issuersWallets: string[] = issuersWalletsRes.data;
      const _rule144Config: Rule144Config = rule144Res.data;
      const _typeAndDetails: TokenTypeAndDetails = typeAndDetailsRes.data;
      const decimals = tokenConfigurationProcess?.isDivisible ? 18 : 0;

      const receipt0 =
        await securityTokenRegistryService.generateNewSecurityToken(
          selectedWallet as string,
          txParams.tokenName,
          symbolDetailsAndSTData?.symbolDetails.symbol as string,
          decimals,
          txParams.additionalTokenInfo,
          sharedService.regOptions.find(
            (regOp) => regOp.value === tokenConfigurationProcess?.regulation
          )?.shortValue as string,
          tokenConfigurationProcess?.regDTransferableOutsideUSA as boolean,
          tokenConfigurationProcess?.creationTS as number,
          tokenConfigurationProcess?.typeOfSecurity as string,
          _typeAndDetails.stock,
          issuersWallets,
          _monthUnit,
          _trustedForwarder,
          {delegate: prop.delegate}
        );

      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt0;
        return current;
      });

      if (!receipt0.status) return;

      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = true;
        return current;
      });

      const _symbolDetailsAndSTData = await updateSymbolDetailsAndSTData();

      const receipt1 = await rule144Facet.saveRule144Config(
        _symbolDetailsAndSTData.securityTokenData.contractAddress,
        selectedWallet as string,
        new BigNumber(_rule144Config.maxAffiliateSellPercentage)
          .times(new BigNumber(10).pow(precision))
          .toFixed(0),
        _rule144Config.maxAffiliateAmountMonths,
        new BigNumber(_rule144Config.maxAffiliateAmount).toFixed(0),
        _rule144Config.maxAffiliateTokensMonths,
        new BigNumber(_rule144Config.maxAffiliateTokens)
          .times(new BigNumber(10).pow(decimals))
          .toFixed(0),
        _rule144Config.lockPeriod,
        {delegate: prop.delegate}
      );
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[1].submitting = false;
        current[1].receipt = receipt1;
        return current;
      });
      if (receipt1.status) setRule144ConfigInitialized(true);
    } catch (err) {
      console.error(err);
    }

    setTransactions((prev) => {
      const current: any[] = JSON.parse(JSON.stringify(prev));
      current.forEach((transaction) => (transaction.submitting = false));
      return current;
    });
  };

  const saveRule144Config = async (prop: {delegate: boolean}) => {
    // console.log(await ownershipFacet.owner(symbolDetailsAndSTData?.securityTokenData.contractAddress as string));

    setIsModalVisible(true);
    setTransactions([{ details: "Saving Rule 144 Configuration", submitting: true }]);
    try {
      const decimals = symbolDetailsAndSTData?.securityTokenData.decimals as string;
      const response = await tokenConfigurationService.getRule144Config(
        tokenConfigurationProcess?.rule144ConfigId as string
      );
      if (!response.success) {
        setTransactions((prev) => {
          const current = JSON.parse(JSON.stringify(prev));
          current[0].submitting = false;
          return current;
        });
        return;
      }
      const _rule144Config: Rule144Config = response.data;
      const receipt = await rule144Facet.saveRule144Config(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        new BigNumber(_rule144Config.maxAffiliateSellPercentage)
          .times(new BigNumber(10).pow(precision))
          .toFixed(0),
        _rule144Config.maxAffiliateAmountMonths,
        new BigNumber(_rule144Config.maxAffiliateAmount).toFixed(0),
        _rule144Config.maxAffiliateTokensMonths,
        new BigNumber(_rule144Config.maxAffiliateTokens)
          .times(new BigNumber(10).pow(decimals))
          .toFixed(0),
        _rule144Config.lockPeriod,
        {delegate: prop.delegate}
      );
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt;
        return current;
      });
      if (receipt.status) setRule144ConfigInitialized(true);
    } catch (err) {
      console.error(err);
    }
    setTransactions((prev) => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  };

  const relaunchToken = async (prop: {delegate: boolean}) => {
    setIsModalVisible(true);
    setTransactions([{ details: "Relaunching Token", submitting: true }]);
    try {
      const shortRegValue = sharedService.regOptions.find(
        (regOp) => regOp.value === tokenConfigurationProcess?.regulation
      )?.shortValue as string;



      const res = await tokenConfigurationService.getTokenTypeAndDetails(tokenConfigurationProcess?.tokenTypeAndDetailsId as string);
      const _typeAndDetails: TokenTypeAndDetails = res.data;

      const receipt = await mainFacet.relaunchToken(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        {
          regulation: shortRegValue,
          regDTransferableOutsideUSA: tokenConfigurationProcess?.regDTransferableOutsideUSA as boolean,
          creationTS: tokenConfigurationProcess?.creationTS as number,
          typeOfSecurity: tokenConfigurationProcess?.typeOfSecurity as string,
          commonStock: _typeAndDetails.stock || '',
        },
        {delegate: prop.delegate}
      );
      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].submitting = false;
        current[0].receipt = receipt;
        return current;
      });
      if (receipt.status)
        setRegulationsLaunched((prev) => {
          const current = sharedService.clone(prev) as RegLaunched[];
          current.push({
            regulation: shortRegValue,
            dateLaunched: Math.floor(Date.now() / 100).toString(),
            regDTransferableOutsideUSA: tokenConfigurationProcess?.regDTransferableOutsideUSA as boolean,
            creationTS: tokenConfigurationProcess?.creationTS.toString() as string,
            index: current.length.toString(),
            legendRemoved: false,
            typeOfSecurity: tokenConfigurationProcess?.typeOfSecurity as string,
            commonStock: _typeAndDetails.stock
          });
          return current;
        });
    } catch (err) {
      console.error(err);
    }
    setTransactions((prev) => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  };

  const _reviewFinalTokenConfig = async (status: "verified" | "rejected") => {
    try {
      if (
        userInfo.role === "issuer counsel" &&
        tokenConfigurationProcess?.regulation === "regulation-s" &&
        ["pending issuer counsel"].includes(
          tokenConfigurationProcess?.status as string
        )
      ) {
        await isserCounselCheckBoxesForm.validateFields();
      }
      if (status === "verified") setVerifiying(true);
      else setRejecting(true);
      await reviewFinalTokenConfig(status, comments);
      if (status === "verified") setVerifiying(false);
      else setRejecting(false);
    } catch (err) {
      console.error(err);
    }
  };

  const _submitTokenConfigForReview = async () => {
    setSubmitting(true);
    await submitTokenConfigForReview();
    setSubmitting(false);
  };

  const _submitCheckboxesStatus = async () => {
    if (issuerQuestionAnswer?.find((checkbox) => !checkbox.isAnswered)) return;
    setSubmitting(true);
    await submitCheckboxesStatus();
    setSubmitting(false);
  };

  const closeModal = async () => {
    window.location.reload();
    setUpdatingSymbolDetailsAndSTData(true);
    if (tokenConfigurationProcess?.tokenSymbol) await updateSymbolDetailsAndSTData();
    setUpdatingSymbolDetailsAndSTData(false);
    setIsModalVisible(false);
  };

  // const saveCheckboxesStatus = async() => {
  //   const response = await tokenConfigurationService.saveCheckboxesStatus({checkboxes: checkBoxesForm.getFieldsValue()});
  //   if (!response.success) console.error(response.error.message);
  // }

  const isNotReserved = !symbolDetailsAndSTData || (!symbolDetailsAndSTData.symbolDetails.isDeployed && Date.now() > +symbolDetailsAndSTData.symbolDetails.expiryDate * 1000);
  const isReservedAndNotDeployed = symbolDetailsAndSTData && !symbolDetailsAndSTData.symbolDetails.isDeployed && Date.now() <= +symbolDetailsAndSTData.symbolDetails.expiryDate * 1000;
  const isDeployed = symbolDetailsAndSTData?.symbolDetails.isDeployed;

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        {
          isNotReserved && userInfo.role === "issuer token admin" && (
            <Col span={16}>
              <h1>Reserve Your Token Symbol</h1>
              <h5>
                Your token symbol will be reserved for <b>15 days</b>, and is
                permanently yours once you create your Token. This reservation
                ensures that no other organization can create a token symbol
                identical to yours using TAP platform.
              </h5>
              <Card style={{ borderColor: "darkgrey" }} bordered={false}>
                <Form onFinish={openTxFeeDelegationModal} form={form}>
                  <h2>Enter Token Symbol</h2>
                  <Form.Item
                    name="tokenSymbol"
                    validateStatus={
                      !tokenSymbolRulesValid
                        ? ""
                        : chekingAvailability
                          ? "validating"
                          : isTokenSymbolAvailable
                            ? "success"
                            : "error"
                    }
                    help={
                      !tokenSymbolRulesValid
                        ? null
                        : chekingAvailability
                          ? null
                          : isTokenSymbolAvailable
                            ? "Symbol available. Reserve NOW!"
                            : "Symbol already reserved"
                    }
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                        whitespace: true,
                      },
                      {
                        max: 10,
                        message: "Up to 10 characters",
                      },
                    ]}
                  >
                    <Input onChange={(e) => setTokenSymbol(e.target.value)} />
                  </Form.Item>
                  <h3>
                    <b>Issuer ETH Address</b>
                  </h3>
                  <Input
                    disabled
                    value={selectedWallet}
                    style={{ textAlign: "center" }}
                  />
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: "3%" }}
                    >
                      Reserve Token Symbol
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          )
        }
        {
          isReservedAndNotDeployed && (
            <Col span={16}>
              <Card bordered={false} className="card-shadow">
                <Row>
                  {
                    userInfo.role !== "issuer token admin" && (
                      <Col span={16}>
                        <Title>Approve Security Token</Title>
                        <Text>
                          Approve the Security token before the token reservation
                          expires. If you let the token reservation expire, the
                          token symbol selected will be available for others to
                          claim.
                        </Text>
                      </Col>
                    )
                  }

                  {
                    userInfo.role === "issuer token admin" && (
                      <Col span={16}>
                        <Title>Create your Security Token</Title>
                        <Text>
                          Create your security token before your token reservation
                          expires. If you let your token reservation expire, the
                          token symbol you selected will be available for others to
                          claim.
                        </Text>
                      </Col>
                    )
                  }

                  <Col push={2} span={6}>
                    <Card
                      bordered={false}
                      className="card-shadow"
                      headStyle={{ backgroundColor: "steelblue", color: "white" }}
                      title="Time left"
                    >
                      {
                        expiryDateCountDown && (
                          <>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.days}
                              </span>{" "}
                              days
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.hours}
                              </span>{" "}
                              hours
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.minutes}
                              </span>{" "}
                              minutes
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {expiryDateCountDown.seconds}
                              </span>{" "}
                              seconds
                            </p>
                          </>
                        )
                      }
                    </Card>
                  </Col>

                  <Col span={24}>
                    {
                      !tokenConfigurationProcess?.legendCreationFile && (
                        <Result title="Legend not created by Issuer Counsel" />
                      )
                    }
                    {
                      tokenConfigurationProcess?.legendCreationFile && !tokenConfigurationProcess.isLegendAdded && (
                        <Result title="Legend not added to your Token" />
                      )
                    }
                    {
                      tokenConfigurationProcess?.isLegendAdded && (
                        <>
                          <br />
                          <br />
                          <br />
                          <Title style={{ textAlign: "center", color: "blue" }}>
                            REVIEW and SUBMIT
                          </Title>
                          {
                            !tokenConfigurationProcess.allCheckboxesSelected && (
                              <div
                                style={{ borderStyle: "solid", borderWidth: "1px" }}
                              >
                                <Text>
                                  Check the box if each of the following information
                                  is already provided. If not, submit the information
                                  and then check. Submit only if all the information
                                  is provided.
                                </Text>
                              </div>
                            )
                          }
                          {
                            tokenConfigurationProcess.allCheckboxesSelected && (
                              <>
                                <br />
                                <Row>
                                  <Col xs={{ span: 24 }} sm={{ offset: 2 }}>
                                    <List style={{ textAlign: "left" }}>
                                      {issuerQuestionAnswer?.map(
                                        (checkbox, index) => (
                                          <List.Item key={index}>
                                            <List.Item.Meta
                                              title={checkbox.question}
                                              description={
                                                <>
                                                  {
                                                    checkbox.type === "attachement" && (
                                                      <a
                                                        href={checkbox.answer}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        Download File
                                                      </a>
                                                    )
                                                  }
                                                  {
                                                    checkbox.type !== "attachement" && (
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html: checkbox.answer,
                                                        }}
                                                      >
                                                      </div>
                                                    )
                                                  }
                                                </>
                                              }
                                            ></List.Item.Meta>
                                          </List.Item>
                                        )
                                      )
                                      }
                                    </List>
                                  </Col>
                                </Row>
                                <hr />
                              </>
                            )}
                          {
                            !tokenConfigurationProcess.allCheckboxesSelected && (
                              <Form
                                form={checkBoxesForm}
                                labelAlign="right"
                                wrapperCol={{ xs: { span: 24 }, sm: { offset: 2 } }}
                                onFinish={_submitCheckboxesStatus}
                              >
                                {issuerQuestionAnswer?.map((checkbox, index) => (
                                  <Fragment key={index}>
                                    <Form.Item
                                      style={{ marginBottom: "0px" }}
                                      valuePropName="checked"
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required",
                                        },
                                      ]}
                                      name={checkbox.question}
                                    >
                                      <Checkbox
                                        style={{ color: "blue" }}
                                        disabled
                                      // onChange={saveCheckboxesStatus}
                                      >
                                        <span style={{ color: "blue" }}>
                                          {checkbox.question}
                                        </span>
                                      </Checkbox>
                                    </Form.Item>
                                  </Fragment>
                                ))
                                }
                                {
                                  tokenConfigurationProcess?.regulation === "regulation-d" && (
                                    <Fragment key={123}>
                                      <Form.Item
                                        style={{ marginBottom: "0px" }}
                                        valuePropName="checked"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                        ]}
                                        name="SECFilled"
                                      >
                                        <Checkbox
                                          style={{ color: "blue" }}
                                        //onChange={saveCheckboxesStatus}
                                        >
                                          <span style={{ color: "blue" }}>
                                            Issuer filed for Regulation D with
                                            Excemption with SEC
                                          </span>
                                        </Checkbox>
                                      </Form.Item>
                                    </Fragment>
                                  )
                                }

                                {tokenConfigurationProcess?.regulation === "regulation-s" && (
                                  <Fragment key={123}>
                                    <Form.Item
                                      style={{ marginBottom: "0px" }}
                                      valuePropName="checked"
                                      rules={[
                                        // {
                                        //   required: true,
                                        //   message: "This field is required",
                                        // },
                                      ]}
                                      name="SECFilled"
                                    >
                                      <Checkbox
                                        style={{ color: "blue" }}
                                      //onChange={saveCheckboxesStatus}
                                      >
                                        <span style={{ color: "blue" }}>
                                          Issuer filed for Regulation S with
                                          Excemption with SEC
                                        </span>
                                      </Checkbox>
                                    </Form.Item>
                                  </Fragment>
                                )
                                }
                                <br />
                                <Row>
                                  <Col xs={{ span: 24 }} sm={{ offset: 4 }}>
                                    <p style={{ fontWeight: "bold" }}>
                                      TOKEN CONFIGURATION
                                    </p>
                                  </Col>
                                </Row>
                                {
                                  tokenConfigCheckboxes.map((checkbox, index) => (
                                    <Fragment key={index}>
                                      <Form.Item
                                        style={{ marginBottom: "0px" }}
                                        valuePropName="checked"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                        ]}
                                        name={checkbox.name}
                                      >
                                        <Checkbox
                                          style={{ color: "blue" }}
                                          disabled
                                        // onChange={saveCheckboxesStatus}
                                        >
                                          <span style={{ color: "blue" }}>
                                            {checkbox.label}
                                          </span>
                                        </Checkbox>
                                      </Form.Item>
                                    </Fragment>
                                  ))
                                }
                                <br />
                                {
                                  userInfo.role === "issuer token admin" && (
                                    <>
                                      <Form.Item
                                        name="safeHarbor"
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     message: "This field is required",
                                      //   },
                                      // ]}
                                      >
                                        <Checkbox
                                          style={{ color: "blue" }}
                                        //onChange={saveCheckboxesStatus}
                                        >
                                          <span style={{ color: "blue" }}>
                                            I understand and agree to safe harbor
                                          </span>
                                        </Checkbox>
                                      </Form.Item>
                                      <Form.Item>
                                        <Button
                                          loading={submitting}
                                          type="primary"
                                          htmlType="submit"
                                          size="large"
                                        >
                                          SUBMIT
                                        </Button>
                                      </Form.Item>
                                    </>
                                  )}
                              </Form>
                            )}
                        </>
                      )}

                    <ReviewComponent
                      tokenConfigurationProcess={tokenConfigurationProcess}
                      userInfo={userInfo}
                      _submitTokenConfigForReview={_submitTokenConfigForReview}
                      isserCounselCheckBoxesForm={isserCounselCheckBoxesForm}
                      _reviewFinalTokenConfig={_reviewFinalTokenConfig}
                      submitting={submitting}
                      isserCounselCheckBoxes={isserCounselCheckBoxes}
                      setComments={setComments}
                      comments={comments}
                      rejecting={rejecting}
                      verifiying={verifiying}
                    />

                    {tokenConfigurationProcess?.status === "verified" &&
                      userInfo.role === "issuer token admin" && (
                        <>
                          <Form
                            form={form}
                            onFinish={openTxFeeDelegationModal}
                            initialValues={{ additionalTokenInfo: "" }}
                          >
                            <h2>Token name</h2>
                            <Form.Item
                              name="tokenName"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                  whitespace: true,
                                },
                                {
                                  max: 20,
                                  message: "Up to 20 characters",
                                },
                              ]}
                            >
                              <Input placeholder="Enter token name" />
                            </Form.Item>
                            <h2>Additional token information</h2>
                            <Form.Item
                              name="additionalTokenInfo"
                              rules={[
                                {
                                  max: 30,
                                  message: "Up to 30 characters",
                                },
                              ]}
                            >
                              <Input placeholder="Paste link here" />
                            </Form.Item>
                            <br />
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                              >
                                Create my Security Token
                              </Button>
                            </Form.Item>
                          </Form>
                        </>
                      )}
                  </Col>
                </Row>
              </Card>
            </Col>
          )
        }
        {
          isDeployed && (
            <Col span={16}>
              <Card bordered={false} className="card-shadow">
                <Result
                  status={"success"}
                  title={
                    <>
                      Token deployed successfully
                      {!isRelaunch && (
                        <>
                          <br />
                          {
                            sharedService.regOptions.find(
                              (regOp) =>
                                regOp.value ===
                                tokenConfigurationProcess?.regulation
                            )?.name as string
                          }{" "}
                          Launched successfully
                        </>
                      )}
                      {isRelaunch && (
                        <>
                          <br />
                          {
                            sharedService.regOptions.find(
                              (regOp) =>
                                regOp.value ===
                                tokenConfigurationProcess?.regulation
                            )?.name as string
                          }{" "}
                          {isRegRelaunched
                            ? "Relaunched successfully"
                            : "Not yet Relaunched"}
                        </>
                      )}
                    </>
                  }
                />

                {!rule144ConfigInitialized &&
                  !loadingRule144ConfigInitialized &&
                  userInfo.role === "issuer token admin" && (
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                      <Button type="primary" onClick={openTxFeeDelegationModal}>
                        Save Rule 144 On Blockchain
                      </Button>
                    </div>
                  )}

                <div>
                  <Title
                    level={2}
                    style={{ fontWeight: "bold", marginBottom: "0" }}
                  >
                    Token name
                  </Title>
                  <Title level={3} style={{ marginTop: "0" }}>
                    {symbolDetailsAndSTData?.securityTokenData.name}
                  </Title>
                </div>
                <br />
                <div>
                  <Title
                    level={2}
                    style={{ fontWeight: "bold", marginBottom: "0" }}
                  >
                    Token address
                  </Title>
                  <a
                    href={`${etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData?.securityTokenData.contractAddress
                      }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Title
                      style={{ color: "royalblue", textDecoration: "underline" }}
                      level={3}
                    >
                      {symbolDetailsAndSTData?.securityTokenData.contractAddress}
                    </Title>
                  </a>
                </div>

                {isRelaunch && !isRegRelaunched && (
                  <>
                    <ReviewComponent
                      tokenConfigurationProcess={tokenConfigurationProcess}
                      userInfo={userInfo}
                      _submitTokenConfigForReview={_submitTokenConfigForReview}
                      isserCounselCheckBoxesForm={isserCounselCheckBoxesForm}
                      _reviewFinalTokenConfig={_reviewFinalTokenConfig}
                      submitting={submitting}
                      isserCounselCheckBoxes={isserCounselCheckBoxes}
                      setComments={setComments}
                      comments={comments}
                      rejecting={rejecting}
                      verifiying={verifiying}
                    />

                    {tokenConfigurationProcess?.status === "verified" &&
                      userInfo.role === "issuer token admin" && (
                        <>
                          <div
                            style={{ textAlign: "center", marginBottom: "10px" }}
                          >
                            <Button
                              type="primary"
                              size="large"
                              onClick={openTxFeeDelegationModal}
                            >
                              Relaunch Token under{" "}
                              {
                                sharedService.regOptions.find(
                                  (regOp) =>
                                    regOp.value ===
                                    tokenConfigurationProcess?.regulation
                                )?.name as string
                              }
                            </Button>
                          </div>
                        </>
                      )}
                  </>
                )}
              </Card>
            </Col>
          )}

        {(isReservedAndNotDeployed || isDeployed) && (
          <Col span={8}>
            <Card
              bordered={false}
              style={{ marginLeft: "20px" }}
              className="card-shadow"
            >
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#d9d9d9",
                  padding: "13px 25px",
                }}
              >
                <p style={{ fontWeight: "bold", color: "black" }}>
                  Token Symbol
                </p>
                <Title level={2} style={{ backgroundColor: "white" }}>
                  {symbolDetailsAndSTData?.symbolDetails.symbol}
                </Title>
              </div>
              <br />
              {/* <div>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Symbol Issuance Transaction
                </p>
                <a
                  href={`${etherscanURL[networkId as string]}/tx/${symbolRegEvent?.transactionHash
                    }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {minifyTxHash(symbolRegEvent?.transactionHash)}
                </a>
              </div> */}
              <br />
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Symbol Issuance Date
                </p>
                {symbolDetailsAndSTData && (
                  <p>
                    {formatDate(
                      symbolDetailsAndSTData.symbolDetails.registrationDate
                    )}
                  </p>
                )}
              </div>
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Issuer's ETH address
                </p>
                {symbolDetailsAndSTData && (
                  <a
                    href={`${etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner
                      }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {minifyAddress(symbolDetailsAndSTData.symbolDetails.owner)}
                  </a>
                )}
              </div>
            </Card>
          </Col>
        )}
      </Row>
      <TransactionModal
        title={
          isRelaunch
            ? "Relaunch of Token"
            : (isNotReserved && "Token Symbol Reservation") ||
            (isReservedAndNotDeployed && "Token Creation") ||
            (isDeployed && "Rule 144 Configuration")
        }
        transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => closeModal()}
        closingModal={updatingSymbolDetailsAndSTData}
      />

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          isRelaunch
            ? relaunchToken({delegate})
            : (isNotReserved && _reserveTokenSymbol({delegate})) ||
            (isReservedAndNotDeployed && createToken({delegate})) ||
            (isDeployed && saveRule144Config({delegate}));

          setIsDelegationModalVisible(false);

        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />

    </>
  );
};