import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Modal,
  message,
  Result,
  Row,
  Col,
  DatePicker,
  Card,
  Checkbox,
  Divider,
} from "antd";
import CoinbasePayment from "./CoinbasePayment";
import OthersPayment from "./OthersPayment";
import { InvestorService } from "../Investor.service";
import { SharedService } from "../../Shared/Shared.service";
import { AuthService } from "../../Shared/Auth.service";
import { SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import PhoneInput from "react-phone-input-2";
import { SecurityTokenService } from "../../Shared/SecurityToken/SecurityToken.service";
import WhitelistFacet from "../../Shared/SecurityToken/Facets/WhitelistFacet/index";
import BigNumber from "bignumber.js";

const investorService = new InvestorService();
const sharedService = new SharedService();

const securityTokenService = new SecurityTokenService();
const whitelistFacet = new WhitelistFacet();

const securityTokenRegisteryService = new SecurityTokenRegistryService();
const authService = new AuthService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 8,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

interface tokenPhase {
  minInvestmentAmount: any;
  bonusPercent: number;
}
interface WireDetail {
  bank_address: string;
  bank_name: string;
  bank_phone: string;
  routing_number: string;
  swift_code: string;
  account_number: string;
  beneficiary_name: string;
  beneficiary_address: string;
  reference: string;
}
const Payments = (props) => {
  const [paymentForm] = Form.useForm();
  const [amountStatus, setAmountStatus] = useState(false);
  const [tokenPhaseDetail, setTokenPhaseDetail] = useState<Array<tokenPhase>>(
    []
  );
  const [creditCardSelected, setCreditCardSelected] = useState(false);
  const [achSelected, setAchSelected] = useState(false);
  const [signSubscription, setSignSubscription] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [wireDetail, setWireDetail] = useState<WireDetail>();
  const [bitcoinDetail, setBitcoinDetail] = useState<any>();
  const [checkDetail, setCheckDetail] = useState<any>();
  const [wireDivison, setWireDivison] = useState(false);
  const [bitcoinDivison, setBitcoinDivison] = useState(false);
  const [checkDivison, setCheckDivison] = useState(false);
  const [signingForm] = Form.useForm();
  const [showAgreement, setShowAgreement] = useState<any>();
  const [signingDivison, setSigningDivison] = useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [checkboxState, setCheckboxState] = useState(false);
  const [signLoader, setSignLoader] = useState(false);
  const [paymentFormDivison, setPaymentFormDivision] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentDivision, setPaymentDivision] = useState(false);
  const [coinbasePaymentDivision, setCoinbasePaymentDivision] = useState(false);
  const [coinbaseSelected, setCoinbaseSelected] = useState(false);
  const [primeTrustSelected, setPrimeTrustSelected] = useState(false);
  const [othersSelected, setOthersSelected] = useState(false);
  const [othersPaymentDiv, setOthersPaymentDiv] = useState(false);
  const userInfo = props.user;

  useEffect(() => {
    console.log("HEAVY USER", props);
    paymentForm.setFieldsValue({
      firstName: `${userInfo?.firstName}`,
      lastName: `${userInfo?.lastName}`,
      email: `${userInfo?.email}`,
      phoneNumber: `${userInfo?.contactNumber}`,
      state: `${userInfo?.state}`,
      country: `${userInfo?.country}`,
      zipCode: `${userInfo?.zipCode}`,
    });
  }, [paymentForm, userInfo]);

  useEffect(() => {
    (async () => {
      const response = await investorService.getTokenPhaseDetail({
        companyId: props.currentToken.issuerInfo[0].companyId,
      });
      if (response.success && response.data) {
        setTokenPhaseDetail(response.data.bonusOffers);
      } else setTokenPhaseDetail([]);
      const paymentMethodResponse = await investorService.getSavedPaymentMethod(
        { companyId: props.currentToken.issuerInfo[0].companyId }
      );
      if (paymentMethodResponse.success && paymentMethodResponse.data) {
        let coinbase = paymentMethodResponse.data.coinbaseSelected;
        let primeTrust = paymentMethodResponse.data.primeTrustSelected;
        let others = paymentMethodResponse.data.othersSelected;
        setCoinbaseSelected(paymentMethodResponse.data.coinbaseSelected);
        setPrimeTrustSelected(paymentMethodResponse.data.primeTrustSelected);
        setOthersSelected(paymentMethodResponse.data.othersSelected);
        // if (coinbase && primeTrust) return;
        // if (coinbase) setCoinbasePaymentDivision(true);
        // if (primeTrust) setPaymentDivision(true);
        // if (others) setOthersPaymentDiv(true);
      } else {
        setPaymentDivision(true);
      }
    })();
  }, [props.currentToken.issuerInfo]);

  const handleSocialSecurityNumber = (event) => {
    if (event.target.value.length == 3) event.target.value += "-";
    if (event.target.value.length == 6) event.target.value += "-";
    paymentForm.setFieldsValue({
      socialSecurityNumber: `${event.target.value}`,
    });
  };

  const cancelSigning = () => {
    setSigningDivison(false);
    setCheckboxStatus(!checkboxStatus);
  };

  const handleTokenCount = (event) => {
    let amount: number = Number(event.target.value.trim().replace(/,/g, ""));
    console.log("Amount || token", event.target.value.trim(), amount, 0);
    let bonus: number = 0;
    let token: number = Number(
      (amount / props.currentToken.pricePerToken) * 100
    );
    tokenPhaseDetail?.map((data) => {
      if (
        data.minInvestmentAmount.min < amount &&
        amount < data.minInvestmentAmount.max
      ) {
        bonus = data.bonusPercent;
        if (bonus > 0) token = token + token * (bonus / 100);
      }
    });
    token = Math.round(token);
    let value = String(token);
    console.log("Amount || token", amount, value);
    paymentForm.setFieldsValue({ no_of_tokens: token });
  };

  const onPaymentMethodChange = (e) => {
    if (e.target.value == "creditCard") {
      setCreditCardSelected(true);
      setAchSelected(false);
    } else if (e.target.value == "ach") {
      setAchSelected(true);
      setCreditCardSelected(false);
    } else {
      setCreditCardSelected(false);
      setAchSelected(false);
    }
  };

  const handleSubscription = async () => {
    if (!checkboxStatus) {
      setAmountStatus(true);
      setSignSubscription(true);
      if (paymentForm.getFieldValue("amountToInvest")) {
        let signingData = {
          equity_share_count: paymentForm.getFieldValue("no_of_tokens"),
          vesting_amount: paymentForm.getFieldValue("amountToInvest"),
          vesting_as:
            paymentForm.getFieldValue("firstName") +
            " " +
            paymentForm.getFieldValue("lastName"),
          vesting_as_email: paymentForm.getFieldValue("email"),
          issuerCompanyId: props.currentToken.issuerInfo[0].companyId,
        };

        const response = await investorService.getSubscriptionAgreement({
          investor: signingData,
        });
        if (response.success || response.data) {
          setCheckboxStatus(!checkboxStatus);
          setShowAgreement(response.data);
          setSigningDivison(true);
          signingForm.setFieldsValue({
            signerName: userInfo.firstName + " " + userInfo.lastName,
            email: userInfo.email,
          });
        } else {
          setCheckboxStatus(checkboxStatus);
          message.error("Something went wrong!! Please try again");
        }
      } else {
        setAmountStatus(false);
        message.warning("Fill all the details");
      }
    } else {
      setCheckboxStatus(checkboxStatus);
      setSigningDivison(true);
    }
  };

  const handleSigningForm = async (data) => {
    setSubmitting(true);
    data.investorId = userInfo._id;
    data.signId =
      showAgreement?.signing_links.subscriber_signature.electronic_signature_id;
    const response = await investorService.signSubscriptionAgreement(data);
    if (response.success) {
      //      await investorService.handleSigningDocument(users).then(response=>setSignURL(response.data));
      //      setHelloSignDoc(true);
      setSubmitting(false);
      setCheckboxState(true);
      setSigningDivison(false);
    } else {
      setSubmitting(true);
      message.error("Something went wrong!! Please try again");
    }
  };

  const onPayment = async (data) => {
    setSubmitting(true);
    if (!checkboxStatus) {
      message.warning("Sign the subscription agreement");
    }
    data.no_of_tokens = Number(data.no_of_tokens);
    data.amountToInvest = Number(data.amountToInvest.replace(/,/g, ""));
    data.email = await userInfo.email;
    data.investorId = userInfo._id;
    data.company = await props.currentToken.issuerInfo[
      "0"
    ].companyName.toLowerCase();
    data.token = props.currentToken;
    data.accept = false;
    data.reject = false;
    data.creationTS = await Date.now();

    const response = await investorService.saveWireTransferPaymentDetails(data);
    if (response.success || response.data) {
      setPaymentFormDivision(true);
      setPaymentSuccess(true);
      if (data.modeOfFundTransfer == "wire") {
        setWireDetail(response.data);
        setWireDivison(true);
      }
      if (
        data.modeOfFundTransfer == "bitcoin" ||
        data.modeOfFundTransfer == "ethereum"
      ) {
        setBitcoinDetail(response.data);
        setBitcoinDivison(true);
      }
      if (data.modeOfFundTransfer == "check") {
        setCheckDetail(response.data);
        setCheckDivison(true);
      }
    } else {
      if (response.error.code) {
        let data: any = response.error.code;
        console.log(data);
        message.error(String(data.message));
      } else message.error("Something went wrong!! Please try again");
    }
    setSubmitting(false);
  };

  const changeView = (e) => {
    const selected = e.target.value;
    console.log(selected);
    if (selected === 1) setCoinbasePaymentDivision(true);
    if (selected === 2) setPaymentDivision(true);
    if (selected === 3) setOthersPaymentDiv(true);
  };

  return (
    <>
      <div
        hidden={
          paymentDivision ||
          coinbasePaymentDivision ||
          othersPaymentDiv
        }
      >
        <p
          style={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "30px",
            color: "#4285F4",
          }}
        >
          Select Payment method
        </p>
        <div style={{ textAlign: "left" }}>
          <Radio.Group onChange={changeView}>
          {coinbaseSelected && <Radio value={1}>CryptoCurrency</Radio>}
          {primeTrustSelected && 
          <Radio
              style={{
                marginTop: "5px",
              }}
              value={2}
            >
              Credit / Debit / ACH / Wire-Transfer / Others
            </Radio>
          }
          {othersSelected && 
          <Radio
              style={{
                marginTop: "5px",
              }}
              value={3}
            >
              Others
            </Radio>
          }
          </Radio.Group>
        </div>
      </div>
      <div hidden={!coinbasePaymentDivision}>
        <p
          style={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "30px",
            color: "#4285F4",
          }}
        >
          INVEST HERE...
        </p>
        <CoinbasePayment
          token={props.currentToken}
          userInfo={userInfo}
        ></CoinbasePayment>
      </div>
      <div hidden={!othersPaymentDiv}>
        <p
          style={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "30px",
            color: "#4285F4",
          }}
        >
          INVEST HERE...
        </p>
        {othersPaymentDiv && (
          <OthersPayment
            token={props.currentToken}
            userInfo={userInfo}
            onBack={props.onBack}
          ></OthersPayment>
        )}
      </div>
      <div hidden={!paymentDivision}>
        <div hidden={paymentFormDivison}>
          <p
            style={{
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "30px",
              color: "#4285F4",
            }}
          >
            INVEST HERE...
          </p>
          <Card style={{ width: "100%", margin: "auto" }}>
            <Form
              {...formItemLayout}
              layout="vertical"
              title="Enter Following details to proceed:"
              form={paymentForm}
              onFinish={onPayment}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "25px",
                  textAlign: "left",
                }}
              >
                Investment Information
              </p>

              <Form.Item
                label="First name"
                name="firstName"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input style={{ color: "green" }} disabled />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lastName"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input style={{ color: "green" }} disabled />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your E-mail",
                    whitespace: true,
                  },
                ]}
              >
                <Input style={{ color: "green" }} disabled />
              </Form.Item>
              <div style={{ display: "flex" }}>
                <Form.Item
                  label="Amount to Invest"
                  name="amountToInvest"
                  style={{ width: "100%" }}
                  normalize={(value: string, prevValue: any, allValues) => {
                    const _value = value.replace(/,/g, "");

                    if (new BigNumber(_value).isGreaterThan(0))
                      return new BigNumber(_value).toFormat(0);
                    if (!_value) return "";
                    return prevValue || "";
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input amount to raise!",
                    },
                  ]}
                >
                  <Input
                    disabled={amountStatus}
                    onChange={handleTokenCount}
                    addonAfter="USD"
                  />
                </Form.Item>
                <Form.Item
                  label="# of tokens"
                  name="no_of_tokens"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Enter the number of shares",
                    },
                  ]}
                >
                  <Input type="number" style={{ color: "#3d61ff" }} disabled />
                </Form.Item>
              </div>
              <Form.Item
                label="Mode of fund transfering :"
                name="modeOfFundTransfer"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Col style={{ textAlign: "left" }}>
                  <Radio.Group onChange={onPaymentMethodChange}>
                    {props.currentToken.regulation !== "regulation-s" && (
                      <>
                        <Radio key="ach" value={"ach"}>
                          ACH(Electronic Check)-US Bank Account Only
                        </Radio>
                        <br />
                        <Radio key="check" value={"check"}>
                          Mail a check
                        </Radio>
                        <br />
                        <Radio key="bitcoin" value={"bitcoin"}>
                          Bitcoin(BTC)
                        </Radio>
                        <br />
                        <Radio key="ethereum" value={"ethereum"}>
                          Ethereum(ETH)
                        </Radio>
                        <br />
                      </>
                    )}
                    <Radio key="wire_transfer" value={"wire"}>
                      Wire Transfer
                    </Radio>
                    <br />
                    <Radio key="creditCard" value={"creditCard"}>
                      Credit Card
                    </Radio>
                    <br />
                  </Radio.Group>
                </Col>
              </Form.Item>
              <Form.Item
                label="Credit card details"
                name="creditCardDetail"
                hidden={!creditCardSelected}
                rules={[
                  {
                    required: creditCardSelected,
                  },
                ]}
              >
                <Col style={{ textAlign: "left" }}>
                  <Card style={{ width: "120%" }}>
                    <Form.Item
                      label="Card Number"
                      name="creditCardNumber"
                      style={{ width: "70%" }}
                    >
                      <Input maxLength={16} />
                    </Form.Item>
                    <Form.Item
                      label="Card Expiration"
                      name="creditCardExpiration"
                    >
                      <DatePicker picker="month" />
                    </Form.Item>
                    <Form.Item label="CVV" name="creditCardCVV">
                      <InputNumber maxLength={3} />
                    </Form.Item>
                    <Form.Item
                      label="Card Type"
                      labelCol={{ span: 12, offset: 2 }}
                      name="cardType"
                    >
                      <Col style={{ textAlign: "left" }}>
                        <Radio.Group>
                          <Radio key="MC" value={"MC"}>
                            MasterCard
                          </Radio>
                          <Radio key="VI" value={"VI"}>
                            Visa
                          </Radio>
                          <br />
                        </Radio.Group>
                      </Col>
                    </Form.Item>

                    <Form.Item
                      label="Billing Name"
                      name="billingName"
                      style={{ width: "70%" }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Billing postal code"
                      name="billingPostalCode"
                      style={{ width: "70%" }}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Card>
                </Col>
              </Form.Item>
              <Form.Item
                label="Account details for ACH mode of payment"
                name="accountDetails"
                hidden={!achSelected}
                rules={[
                  {
                    required: achSelected,
                  },
                ]}
              >
                <Col style={{ textAlign: "left" }}>
                  <Card style={{ width: "120%" }}>
                    <Form.Item label="Name on Account" name="accountName">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Account Number" name="accountNumber">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Routing Number" name="routingNumber">
                      <Input maxLength={9} />
                    </Form.Item>
                    <Form.Item label="Bank address" name="bankAddress">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Account Type" name="accountType">
                      <Col style={{ textAlign: "left" }}>
                        <Radio.Group>
                          <Radio key="saving" value={"saving"}>
                            Saving
                          </Radio>
                          <br />
                          <Radio key="checking" value={"checking"}>
                            Checking
                          </Radio>
                          <br />
                        </Radio.Group>
                      </Col>
                    </Form.Item>
                    <Form.Item label="Check type" name="checkType">
                      <Col style={{ textAlign: "left" }}>
                        <Radio.Group>
                          <Radio key="business" value={"business"}>
                            Business
                          </Radio>
                          <br />
                          <Radio key="personal" value={"personal"}>
                            Personal
                          </Radio>
                          <br />
                        </Radio.Group>
                      </Col>
                    </Form.Item>
                  </Card>
                </Col>
              </Form.Item>
              {props.currentToken.regulation !== "regulation-s" && (
                <>
                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    <sub style={{ color: "red", fontSize: "22px" }}>*</sub>A
                    financial advisor assiting me or filling this form on the
                    behalf of the investor
                  </p>
                  <Form.Item
                    label=""
                    name="is_assiting"
                    labelCol={{ span: 18, offset: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Col style={{ textAlign: "left" }}>
                      <Radio.Group>
                        <Radio key="yes" value={"yes"}>
                          Yes
                        </Radio>
                        <Radio key="no" value={"no"}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </Form.Item>
                </>
              )}
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "25px",
                  textAlign: "left",
                }}
              >
                Investor Details
              </p>
              <Form.Item
                label="Country"
                name="country"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input style={{ color: "green" }} />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                    message: "Enter the City !!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="State"
                name="state"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                    message: "Enter the state !!",
                  },
                ]}
              >
                <Input style={{ color: "green" }} />
              </Form.Item>
              <Form.Item
                label="Zip code"
                name="zipCode"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                    message: "Enter the Zip Code !!",
                  },
                ]}
              >
                <Input style={{ color: "green" }} />
              </Form.Item>

              {(props.user.country ===
                "United Kingdom of Great Britain and Northern Ireland" ||
                props.user.country === "GB") && (
                <>
                  <div style={{ display: "flex" }}>
                    <Form.Item
                      label="Building Name"
                      name="buildingName"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Enter the Building Name !!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="County"
                      name="county"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Enter the County Name !!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Form.Item
                      label="Street Name"
                      name="streetName"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Enter the Street Name !!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Street Number"
                      name="streetNumber"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Enter the Street Number !!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Form.Item
                      label="Street Type"
                      name="streetType"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Enter the Street Type !!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Unit Number"
                      name="unitNumber"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Enter the Unit Number !!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </>
              )}
              <Form.Item
                label="Alternative email"
                name="alternativeEmail"
                style={{ width: "70%" }}
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Enter the Phone Number !!",
                  },
                ]}
              >
                <PhoneInput />
              </Form.Item>
              <Form.Item
                label="Social security number"
                name="socialSecurityNumber"
                style={{ width: "70%" }}
                rules={[
                  {
                    required: true,
                    message: "  ",
                  },
                  {
                    validator: (rule, value) => {
                      const strongRegex = /^\d{3}-\d{2}-\d{4}$|^\d{2}\d{7}$/;
                      if (strongRegex.test(value)) return Promise.resolve();
                      return Promise.reject(
                        "Enter the Social security number of 9 digit !!"
                      );
                    },
                  },
                ]}
              >
                <Input maxLength={11} onChange={handleSocialSecurityNumber} />
              </Form.Item>
              {props.currentToken.regulation !== "regulation-s" && (
                <>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      textAlign: "left",
                    }}
                  >
                    Investor Questionnaire
                  </p>
                  <p
                    style={{
                      fontStyle: "oblique",
                      fontWeight: "bold",
                      textAlign: "justify",
                    }}
                  >
                    This information is needed for us to comply with SEC and
                    stable securities regulations.We ask the following questions
                    to determine if the amount if the amount you may invest is
                    limited by law.
                  </p>
                  <br />
                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    Are you an "accrediated" investor (i.e do you earn over
                    $200,000 per year, have a net worth of $1M or more ,or are
                    an indivisual investor)?
                  </p>
                  <Form.Item
                    label=""
                    name="accrediated"
                    style={{ fontSize: "25px" }}
                    labelCol={{ span: 24, offset: 4 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Col style={{ textAlign: "left" }}>
                      <Radio.Group>
                        <Radio key="yes" value={true}>
                          Yes
                        </Radio>
                        <Radio key="no" value={false}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </Form.Item>
                  <p
                    style={{
                      fontStyle: "oblique",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    Are you an "accrediated investor" the law limits the total
                    amount you can invest based on your annual income and your
                    net worth. Please provide these so that we may determine if
                    the amount you wish to invest is within these limitation.
                  </p>
                  <Form.Item
                    label="Annual Income"
                    name="annualIncome"
                    rules={[
                      {
                        required: true,
                        message: "Enter your Annual Income !!",
                      },
                    ]}
                  >
                    <Input addonAfter="USD" />
                  </Form.Item>
                  <Form.Item
                    label="Net Worth"
                    name="netWorth"
                    rules={[
                      {
                        required: true,
                        message: "Enter the net worth of your income !!",
                      },
                    ]}
                  >
                    <Input addonAfter="USD" />
                  </Form.Item>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      textAlign: "left",
                    }}
                  >
                    Substitute Form W-9 Statement :
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    <sub style={{ color: "red", fontSize: "22px" }}>*</sub>
                    Under penality of perjury, by accepting the aggrement below
                    I certify that i have provided my correct tax payer
                    identification number and,
                  </p>
                  <Form.Item
                    label=""
                    name="usCitizen"
                    style={{ fontSize: "25px" }}
                    labelCol={{ span: 26, offset: 4 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Col
                      style={{
                        textAlign: "left",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <Radio.Group>
                        <Radio key="yes" value={"yes"}>
                          I am US citizen
                        </Radio>
                        <Radio key="no" value={"no"}>
                          I am not a US citizen
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </Form.Item>
                  <p style={{ textAlign: "left", fontWeight: "bold" }}>
                    <sub style={{ color: "red", fontSize: "22px" }}>*</sub>
                    And :
                  </p>
                  <Form.Item
                    label=""
                    name="withHolding"
                    labelCol={{ span: 26, offset: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Col style={{ textAlign: "left" }}>
                      <Radio.Group>
                        <Radio key="yes" value={"yes"}>
                          I am exempt from backup withHolding
                        </Radio>
                        <Radio
                          key="no"
                          value={"no"}
                          style={{ textAlign: "justify" }}
                        >
                          I am a subject to backup withHolding
                        </Radio>
                        <p>
                          (Only check this option when you've been notified by
                          the IRS that you are subject to backup withHolding)
                        </p>
                      </Radio.Group>
                    </Col>
                  </Form.Item>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      textAlign: "justify",
                    }}
                  >
                    Confirm Investor Accrediation :
                  </p>
                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    The SEC requires that all investors in 506(c) offerings be
                    verified as to their accrediated investor status. The Issuer
                    (Ultimate Test LLC) or selected service will reach out to
                    confirm your accrediation status. Per SEC Regulations you
                    will not be able to finalize your investment if you do not
                    completes this accrediated investor process.
                  </p>
                  <Form.Item
                    label=""
                    name="investorAccrediationConfirm"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Col style={{ textAlign: "left" }}>
                      <Radio.Group>
                        <Radio key="yes" value={"yes"}>
                          Yes
                        </Radio>
                        <Radio key="no" value={"no"}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </Form.Item>
                </>
              )}
              <Col style={{ textAlign: "left" }}>
                <Checkbox
                  checked={checkboxStatus}
                  disabled={checkboxState}
                  onChange={handleSubscription}
                >
                  <sub style={{ color: "red", fontSize: "22px" }}>*</sub>Sign
                  Subscription Agreement
                </Checkbox>
              </Col>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button htmlType="button" onClick={() => props.onBack()}>
                  Back
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div hidden={!paymentSuccess}>
          <Result
            status="success"
            title="Your payment details are submitted successfully!"
            subTitle="Please check your email for next steps."
            extra={[
              <Button
                type="primary"
                onClick={() => {
                  setPaymentSuccess(false);
                  setPaymentDivision(false);
                  props.onBack();
                }}
              >
                NEXT
              </Button>,
            ]}
          >
            {checkDetail && (
              <div hidden={!checkDivison}>
                <Divider />
                <div style={{ backgroundColor: "#142A44", padding: "15px" }}>
                  <sub
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "35px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    Check Details
                  </sub>
                </div>
                <br />
                <Row>
                  <div>
                    <sub
                      style={{
                        color: "#666770",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "right",
                        fontSize: "15px",
                      }}
                    >
                      Check mailing address :
                    </sub>
                    <sub
                      style={{
                        color: "#050505",
                        fontSize: "20px",
                        textAlign: "left",
                      }}
                    >
                      :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {checkDetail?.check_mailing_address}
                    </sub>
                  </div>
                  <div>
                    <sub
                      style={{
                        color: "#666770",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      Check mailing instruction
                    </sub>
                    <sub
                      style={{
                        color: "#050505",
                        fontSize: "20px",
                        textAlign: "left",
                      }}
                    >
                      :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {checkDetail?.check_mailing_instructions}
                    </sub>
                  </div>
                </Row>
                <br />
              </div>
            )}
            {bitcoinDetail && (
              <div hidden={!bitcoinDivison}>
                <Divider />
                <div style={{ backgroundColor: "#142A44", padding: "15px" }}>
                  <sub
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "35px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    Crypto wallet details
                  </sub>
                </div>
                <br />
                <Row>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Wallet address
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {bitcoinDetail?.address}
                      </sub>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Crypto amount
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {bitcoinDetail?.crypto_amount}
                      </sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <p style={{ fontSize: "15px", fontWeight: "bold" }}>QR Code</p>
                <Fragment>
                  <iframe
                    src={bitcoinDetail?.qr_bin_url}
                    style={{ margin: "auto", height: 260 }}
                    width={260}
                  />
                </Fragment>
              </div>
            )}
            {wireDetail && (
              <div hidden={!wireDivison}>
                <Divider />
                <div style={{ backgroundColor: "#142A44", padding: "15px" }}>
                  <sub
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "35px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    Wire Details
                  </sub>
                </div>
                <br />
                <Row>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Bank Name
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.bank_name}
                      </sub>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Bank phone number
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.bank_phone}
                      </sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Bank Address
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.bank_address}
                      </sub>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Routing Number
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.routing_number}
                      </sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Swift Code
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.swift_code}
                      </sub>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Account number
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.account_number}
                      </sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Beneficiary Name
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.beneficiary_name}
                      </sub>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Beneficiary Address
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.beneficiary_address}
                      </sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={12}>
                    <div>
                      <sub
                        style={{
                          color: "#666770",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Reference
                      </sub>
                      <br />
                      <sub
                        style={{
                          color: "#050505",
                          fontSize: "20px",
                          textAlign: "left",
                        }}
                      >
                        {wireDetail?.reference}
                      </sub>
                    </div>
                  </Col>
                </Row>
                <br />
              </div>
            )}
          </Result>
        </div>

        {showAgreement && (
          <div hidden={!signingDivison}>
            <Modal
              visible={signingDivison}
              title="Subscrption Agreement"
              style={{ top: 30 }}
              footer={null}
              onCancel={() => cancelSigning()}
              width={1000}
            >
              <div style={{ paddingLeft: "20px" }}>
                <Fragment>
                  <iframe
                    srcDoc={showAgreement.body_html}
                    style={{ height: 600 }}
                    width={900}
                  />
                </Fragment>
              </div>
              <div>
                <Form
                  {...formItemLayout}
                  layout="vertical"
                  title="Enter following details to proceed:"
                  form={signingForm}
                  onFinish={handleSigningForm}
                >
                  <Form.Item
                    label="Signature literal"
                    name="sign"
                    rules={[
                      {
                        required: true,
                        message: "Please input Signer Name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Name of signer"
                    name="signerName"
                    rules={[
                      {
                        required: true,
                        message: "Please input Signer Name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                    >
                      Sign
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default Payments;
